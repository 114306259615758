import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/general/`

export const generalApiService = createApi({
    reducerPath: 'generalApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getData: builder.query({
            query: () => `get-data`
        }),
        uploadImage: builder.mutation({
            query: (args) => ({
                url: 'upload',
                method: 'POST',
                body: args
            })
        })
    })
})

export const {
    useGetDataQuery,
    useUploadImageMutation
} = generalApiService