import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/user/`

export const userApiService = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        createUser: builder.mutation({
            query: (payload) => ({
                url: 'create',
                method: 'POST',
                body: payload
            })
        }),
        updateUser: builder.mutation({
            query: (payload) => ({
                url: 'update',
                method: 'PUT',
                body: payload
            })
        }),
        handleWishlist: builder.mutation({
            query: (id) => ({
                url: `handle-wishlist/${id}`,
                method: 'PUT'
            })
        }),
        getInstructors: builder.query({
            query: (data) => {
                if (data?.id) {
                    return `instructors/${data?.id}`
                }

                return `instructors`
            }
        }),
        getWishlist: builder.query({
            query: () => `wishlist`
        }),
    })
})

export const {
    useCreateUserMutation,
    useUpdateUserMutation,
    useHandleWishlistMutation,
    useGetInstructorsQuery,
    useGetWishlistQuery
} = userApiService