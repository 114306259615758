import { Suspense } from 'react';
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './components/loader';
import { store } from './redux/Store';
import Router from './router';

let persistor = persistStore(store)

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Suspense fallback={<Loader loading={true} />}>
          <ToastContainer />
          <Router />
        </Suspense>
      </PersistGate>
    </Provider>
  );
}

export default App;
